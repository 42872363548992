export  const reducers={

    setActiveEducationSelectedOption(state,action){
        state.activeEducationSelectedOption=action.payload
    },

    setIsDrawerVisible(state, action){
        state.isDrawerVisible=action.payload
    }


}

export const reducers = {
  setPublishedCourses(state, action) {
    state.publishedCourses = action.payload;
  },
  setDraftCourses(state, action) {
    state.draftCourses = action.payload;
  },
  setAssignedCourses(state, action) {
    state.assignedCourses = action.payload;
  },
  SetLoading(state, action) {
    state.isLoading = action.payload;
  },
  SetAssignedCoursesLoading(state, action) {
    state.isAssignedCoursesLoading = action.payload;
  },
  setShowAddCourseModal(state, action) {
    state.showAddCourseModal = action.payload;
  },
  setShowCourseAgendaModal(state, action) {
    state.showCourseAgendaModal = action.payload;
  },
  setSelectedCourse(state, action) {
    state.selectedCourse = action.payload;
  },
  setSelectedCourses(state, action) {
    state.selectedCourse = action.payload;
  },
  setShowAssignCourseModal(state, action) {
    state.showAssignCourseModal = action.payload;
  },
  setShowRenameCourseModal(state, action) {
    state.showRenameCourseModal = action.payload;
  },
  setShowDeleteCourseModal(state, action) {
    state.showDeleteCourseModal = action.payload;
  },
  setShowSuggestCourseModal(state, action) {
    state.showSuggestCourseModal = action.payload;
  },
  setResetCourses(state) {
    state.isLoading = true;
  },
  setCurrentTab(state, action) {
    state.currentTab = action.payload;
  },
  setPagination(state, action) {
    state.pagination = action.payload;
  },
  setPublicCourses(state, action) {
    state.publicCourses = action.payload;
  },
  setPrivateCourses(state, action) {
    state.privateCourses = action.payload;
  },
  setUserCreatedCourses(state, action) {
    state.userCreatedCourses = action.payload;
  },
  setOnGoingCourses(state, action) {
    state.onGoingCourses = action.payload;
  },
  setOnGoing(state, action) {
    state.onGoing = action.payload;
  },
  setEc2folder(state, action) {
    state.ec2_folder = action.payload;
  },
  setUploadSucessfull(state, action) {
    state.uploadSuccessfull = action.payload;
  },
  setFileSelected(state, action) {
    state.fileSelected = action.payload;
  },
  setLoadingScorm(state, action) {
    state.loadingScorm = action.payload;
  },
};

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { message } from "antd";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "antd/dist/antd.min.css";
import "./index.scss";
import "./i18n.js";
import "./global.scss";

import store from "./store/index.js";

//components
import App from "./components/authorized/App";
import { Suspense } from "react";
import { registerServiceWorker } from "../src/components/authorized/firebase";
// import { SpeedInsights } from "@vercel/speed-insights/react";
// import { Analytics } from "@vercel/analytics/react";
message.config({ maxCount: 1 });
//antd throwing error for findDOMNode
// const consoleError = console.error.bind(console);
// console.error = (errObj, ...args) => {
//   if (args.includes("findDOMNode")) {
//     return;
//   }
//   consoleError(errObj, ...args);
// };
// useEffect(() => {
//   const orgId = useSelector((state) => state.auth.org_id);
//   const handleThemeChange = (theme) => {
//     console.log("val = ", theme);

//     document.documentElement.style.setProperty("--primary-bg", theme.pbg);
//     document.documentElement.style.setProperty("--primary-text", theme.pcolor);
//     document.documentElement.style.setProperty("--secondary-bg", theme.sbg);
//     document.documentElement.style.setProperty(
//       "--secondary-text",
//       theme.scolor
//     );
//     document.documentElement.style.setProperty("--text-color", theme.textColor);
//     document.documentElement.style.setProperty(
//       "--accent-color",
//       theme.accentColor
//     );
//     document.documentElement.style.setProperty(
//       "--primary-filter",
//       theme.pfilter
//     );
//     document.documentElement.style.setProperty(
//       "--secondary-filter",
//       theme.sfilter
//     );
//     document.documentElement.style.setProperty("--text-filter", theme.tfilter);
//     document.documentElement.style.setProperty(
//       "--accent-filter",
//       theme.afilter
//     );
//   };

//   const setThemeFn = async () => {
//     // setLoading(true);
//     if (orgId != undefined || orgId != null) {
//       // setLoading(true);
//       console.log(orgId);

//       try {
//         await axiosInstance
//           .post("/theme/get_theme", { orgId: orgId })
//           .then((val) => {
//             console.log("theme data = ", val.data);
//             if (val.data.message === "ok") {
//               console.log(JSON.parse(val.data.data));
//               handleThemeChange(JSON.parse(val.data.data));
//             }
//             if (val.data.message === "apply_default") {
//               console.log(JSON.parse(val.data.data));
//               // handleThemeChange(defaultTheme);
//             }
//           });
//         // setLoading(false);
//       } catch (e) {
//         // setLoading(false);
//         console.log("error");
//       } finally {
//         // setLoading(false);
//       }
//     }
//   };
//   setThemeFn();
// }, []);
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <React.Suspense fallback="Loading...">
          <App />
        </React.Suspense>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

registerServiceWorker();

import moment from "moment";
export const GATEWAY_URL =
  process.env.REACT_APP_ENV === "development"
    ? `http://localhost:4000`
    : process.env.REACT_APP_ENV === "staging"
    ? "https://backend.kognics.com"
    : "https://backend.kognics.com";

//  "https://lms-frontend-staging-lmsbackend-staging.mnqeqt.easypanel.host"

console.log(process.env.REACT_APP_ENV);
// this is separate  service
// Ignore below one
export const SIGNUP_SERVICE_GATEWAY_URL =
  process.env.REACT_APP_ENV === "development" &&
  // ? "http://localhost:5000"
  "https://signup.kognics.com";

export const quizConstants = {
  NEXT: "NEXT",
  PREVIOUS: "PREVIOUS",
  VIEW_INSTRUCTION: "INSTRUCTION",
  VIEW_QUESTION: "QUESTION",
  SINGLE_CHOICE: "singleChoice",
  MULTI_CHOICE: "multiChoice",
  DESCRIPTIVE: "descriptive",
  MATCHING: "matching",
  BLANK: "blank",
  FAILED: "FAILED",
  PASSED: "PASSED",
  UNDERREVIEW: "UNDERREVIEW",
};

export const ADD_LESSON_VIEW_ENUM = {
  ADD_LESSON: "ADD_LESSON",
  TEXT_EDITOR: "TEXT_EDITOR",
  ADD_ASSET: "ADD_ASSET",
};

export const courseConstants = {
  NEXT: "NEXT",
  PREVIOUS: "PREVIOUS",
  FAILED: "FAILED",
  PASSED: "PASSED",
  DEFAULT__COMPLETION__SCORE: 100,
};

export const playerConstants = {
  VIEW_OVERVIEW: "OVERVIEW",
  VIEW_CONTENT: "CONTENT",
  MODAL__TYPE__COURSE: "COURSE",
  MODAL__TYPE__QUIZ: "QUIZ",
};

export const default_MOMENT_FORMAT = moment().format("YYYY-MM-DD HH:mm:ss");

export const CLOUDFRONT_URL = "https://d2s9pyuyxqivbc.cloudfront.net/";
export const htmlWithTable2 = `<center >
       
</center>`;

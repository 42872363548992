export const reducers = {
  loginUser(state, action) {
    state.accessToken = action.payload.accessToken;
    state.isLoggedIn = action.payload.isLoggedIn;
    state.user = action.payload.user;
    state.permissions = action.payload.permissions;
    state.orgId = action.payload.orgId;
    state.role = action.payload.permissions?.name;
  },
  checkingUserToken(state, action) {
    state.isCheckingToken = action.payload;
  },
  setLoginButtonDisable(state, action) {
    state.disableLoginButton = action.payload;
  },
  //streaks-web
  setcurrentStreak(state, action) {
    console.log("Setting current streak: ", action.payload); // streak value
    state.currentStreak = action.payload;
  },
  setStreakDays(state, action) {
    console.log("Setting streak days: ", action.payload); // streak days value
    state.streakDays = action.payload;
  },
  setStreakModalOpen(state, action) {
    state.openstreakmodal = action.payload;
  },
  //streak-Brokenstreak
  setBrokenStreakModalOpen(state, action) {
    state.openbrokenstreakmodal = action.payload;
  },
  setFollowedUsersCount(state, action) {
    state.followedUsersCount = action.payload;
  },
  updateFollowedUsersCount: (state, action) => {
    state.followedUsersCount = action.payload;
  },
};

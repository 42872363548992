export const initialState = {
  isLoggedIn: false,
  disableLoginButton: false,
  role: "learner",
  accessToken: "",
  isCheckingToken: true,
  user: {},
  orgId: undefined,
  permissions: {},
  currentStreak: 0,
  openstreakmodal: false,
  openbrokenstreakmodal: false,
  streakDays: [false, true, true, true, true, true, false],
  followedUsersCount: 0,
  updateFollowedUsersCount: 0,
};

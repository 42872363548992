import { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { getAccessToken } from "../../store/auth/AuthExtraReducers.js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//components
import PageLoader from "../PageLoader/index.js";
import CreateTemplate from "./Settings/EmailTemplates.js/components/CreateTemplate.js";
const ScormPlayer = lazy(() => import("./Courses/Common/ScormPlayer.js"));
const CreateProductHub = lazy(() => import("./ProductHub/CreateProductHub.js"));
const FeedbackDetails = lazy(() =>
  import("./Feedback/PublishFeedbackDetails/FeedbackDetails.js")
);
const QuestionCard = lazy(() =>
  import("./Feedback/feedback-viewer/QuestionCard.js")
);
const FeedbackCreatedQues = lazy(() =>
  import("./Feedback/feedback-question/FeedbackCreatedQues.js")
);

const Login = lazy(() => import("../unauthorized/login"));
const TextEditor = lazy(() => import("../unauthorized/TextEditor"));
const TrainingDetails = lazy(() =>
  import("./OnlineTraining/TrainingDetails/TrainingDetails.js")
);

const ForgotPasswordForm = lazy(() =>
  import("../unauthorized/forgotPassword.js")
);
const PrimaryLayout = lazy(() => import("./PrimaryLayout"));
const CreateCourse = lazy(() => import("./NewCreateCourse"));
const CreateGroup = lazy(() => import("./CreateGroup"));

const CreateAssets = lazy(() => import("./CreateAssets"));
const CreateReports = lazy(() => import("./CreateReports"));
const CreateLearningPath = lazy(() => import("./CreateLearningPath"));
const CreateCertificate = lazy(() =>
  import("./Settings/Certificates/CreateCertificate.js")
);
const GenerateCertificate = lazy(() =>
  import("./Settings/Certificates/GenerateCertificate.js")
);
const ExternalFeedback = lazy(() => import("./ExternalFeedback/index.js"));
const ExternalQuiz = lazy(() => import("./ExternalQuiz/index.js"));
const AssetViewer = lazy(() => import("./Assets/assetViewer"));
const Player = lazy(() => import("./Courses/Player/index.js"));
const FeedbackViewer = lazy(() =>
  import("./Feedback/feedback-viewer/FeedbackViewer")
);

const App = () => {
  console.log("opned");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = location;
  const isCheckingToken = useSelector((state) => state.auth.isCheckingToken);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const permissions = useSelector((state) => state.auth.permissions);
  console.log("all the permissions are:", permissions);
  const [showPopup, setShowPopup] = useState(true);
  // this comment for testing purpose
  useEffect(() => {
    // Check if the user agent indicates a mobile device
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
        navigator.userAgent
      );
    console.log(isMobileDevice, navigator.userAgent);

    if (isMobileDevice) {
      setShowPopup(false);
    }
  }, []);

  console.log("Push Notification", "Push notification on staging1.090");

  console.log("Push Notification", "Push notification on staging");
  const handleOpenMobileApp = () => {
    // Code to handle opening the mobile application
    // This could include deep linking or redirecting to the app store
    // After handling the action, you can close the popup
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.kognics.kognics&hl=en&gl=US";
    setShowPopup(false);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const courseId = searchParams.get("courseId");
    const published = searchParams.get("published");

    if (
      pathname.includes("/create-course") &&
      searchParams.has("tab") &&
      searchParams.get("tab") === "build" &&
      courseId &&
      published === "true"
    ) {
      navigate("/courses/dashboard");
    }
  }, []);

  useEffect(() => {
    dispatch(getAccessToken());
  }, []);

  if (!showPopup) {
    return (
      <div className="popup-container">
        <div className="popup">
          <h3>Open the kognics mobile application for the best experience.</h3>
        </div>
      </div>
    );
  }

  if (isCheckingToken) return <PageLoader />;

  if (!isLoggedIn) {
    return (
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="mview/text-editor" element={<TextEditor />} />
          <Route path="forgot-password" element={<ForgotPasswordForm />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
          <Route path="external-feedback" element={<ExternalFeedback />} />
          <Route path="external-quiz" element={<ExternalQuiz />} />
        </Routes>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {permissions.managecourse && (
          <Route path="create-course" element={<CreateCourse />} />
        )}
        {permissions.manageasset && (
          <Route path="create-assets/*" element={<CreateAssets />} />
        )}
        {permissions.managelearningpath && (
          <Route path="create-learning-path" element={<CreateLearningPath />} />
        )}

        {/* ^^^^^^^^^^^^^^^^^//add permission on creategroup section^^^^^^^^^^^^^^^ */}

        {permissions.managegroups && permissions.accesssettings && (
          <Route path="create-group" element={<CreateGroup />} />
        )}
        {/* ^^^^^^^^^^^^^^^^^//add permission on creategroup section^^^^^^^^^^^^^^^ */}

        {permissions.accessreport && (
          <Route
            path="create-reports/:recordType"
            element={<CreateReports />}
          />
        )}

        {/* <Route path="generate-certificate" element={<GenerateCertificate />} /> */}

        {permissions.managecertificate && (
          <Route path="create-certificate" element={<CreateCertificate />} />
        )}
        {permissions.manageproducthub === 1 && (
          <Route path="create-product-hub/*" element={<CreateProductHub />} />
        )}
        <Route path="feedback/:feedbackId" element={<FeedbackCreatedQues />} />
        {permissions.feedbackDraftAndCreations != 0 && (
          <Route path="feedbackdetails/:id" element={<FeedbackDetails />} />
        )}

        <Route path="feedback/questioncard" element={<QuestionCard />} />
        {/* {
          permissions.feedbackDraftAndCreations && 
          <Route path="/feedback/view/:id" element={<FeedbackViewer />} />
        } */}

        <Route
          path="asset-viewer/:assetType/:recourceId"
          element={<AssetViewer />}
        />
        <Route path="create-template" element={<CreateTemplate />} />
        <Route path="courses/player/:courseId" element={<Player />} />
        <Route path="scorm-course" element={<ScormPlayer />} />
        <Route path="*" element={<PrimaryLayout />} />
      </Routes>
    </Suspense>
  );
};

export default App;

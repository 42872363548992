export const initialState = {
  onGoingCourses: [],
  publishedCourses: [],
  publicCourses: [],
  privateCourses: [],
  userCreatedCourses: [],
  draftCourses: [],
  assignedCourses: [],
  isLoading: true,
  isAssignedCoursesLoading: true,
  pagination: {},
  showAddCourseModal: false,
  selectedCourse: {},
  selectedCourses: [],
  showCourseAgendaModal: false,
  showDeleteCourseModal: false,
  showAssignCourseModal: false,
  showRenameCourseModal: false,
  showSuggestCourseModal: false,
  currentTab: "active",
  onGoing: [],
  ec2_folder: "",
  fileSelected: [],
  loadingScorm: false,
  uploadSuccessfull: false,
};

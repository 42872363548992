export const reducers = {
  setData(state, action) {
    state.data = action.payload;
  },
  setActiveCourses(state, action) {
    state.activeCourses = action.payload;
  },
  setActiveLessonData(state, action) {
    state.activeLessonData = action.payload;
  },
  setActiveAssets(state, action) {
    state.activeAssets = action.payload;
  },
  setCurrentLearningPathId(state, action) {
    state.currentLearningPathId = action.payload;
  },
  setCurrentLearningPath(state, action) {
    state.currentLearningPath = action.payload;
  },
  setAlreadyAddedAssets(state, action) {
    if (action.payload.delete) {
      state.alreadyAddedAssets = state.alreadyAddedAssets.filter(
        (asset) =>
          asset !== action.payload.assetId || asset !== action.payload.topicId
      );
    } else {
      state.alreadyAddedAssets = state.alreadyAddedAssets.concat(
        action.payload
      );
    }
  },
  setAlreadyAddedCourses(state, action) {
    // if (action.payload.delete) {
    //   state.alreadyAddedCourses = state.alreadyAddedCourses.filter(
    //     (asset) =>
    //       asset !== action.payload.assetId || asset !== action.payload.topicId
    //   );
    // } else {
    state.alreadyAddedCourses = state.alreadyAddedCourses.concat(
      action.payload
    );
    // }
  },
  setActiveTopic(state, action) {
    state.activeTopic = action.payload;
  },

  setDefaultValue(state) {
    state.data = [];
    state.activeLesson = undefined;
    state.activeAssets = [];
    state.alreadyAddedAssets = [];
    state.alreadyAddedCourses = [];
    state.currentLearningPathId = undefined;
    state.currentLearningPath = {};
    state.activeTopic = undefined;
  },
};

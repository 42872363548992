export const reducers = {
  setRecordModalVisible(state, action) {
    state.courses = action.payload;
  },
  setCreateAssetName(state, action) {
    state.createAssetName = action.payload;
  },
  setAssetFetching(state, action) {
    state.loading = action.payload;
  },
  setAssetSearchQuery(state, action) {
    state.search = action.payload;
  },
  setAssetSortType(state, action) {
    state.sortType = action.payload;
  },
  setAssetSortOrder(state, action) {
    state.sortOrder = action.payload;
  },
  setPublishedAssets(state, action) {
    state.publishedAssets = action.payload;
  },
  setDraftAssets(state, action) {
    state.draftAssets = action.payload;
  },
  setAssetTypes(state, action) {
    state.assetTypes = action.payload;
  },
  setUploadedDate(state, action) {
    state.uploadDate = action.payload;
  },
  setCustomDate(state, action) {
    state.customDate = action.payload;
  },
  setResetFilters(state) {
    state.assetTypes = [];
    state.uploadDate = "all";
    state.customDate = [];
  },
  setResetAssets(state) {
    state.draftAssets = [];
    state.publishedAssets = [];
    state.loading = true;
  },
  setCurrentTab(state, action) {
    state.currentTab = action.payload;
  },
  setFilterType(state, action) {
    state.filterType = action.payload;
  },
  setFilter(state, action) {
    state.filter = action.payload;
  },
  setIsLive(state, action) {
    state.isLive = action.payload;
  },
  setIsRandomize(state, action) {
    state.isRandomize = action.payload;
  },
  setDateTimeValueQuiz(state, action) {
    state.dateTimeValuequiz = action.payload;
  },
};
